<!--
 * @Author: your name
 * @Date: 2019-07-31 16:22:20
 * @LastEditTime: 2020-10-29 14:13:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \trunke:\weball\beijing\Acdp\trunk\src\pages\property\propertyTypeView.vue
-->
<template>
  <div class="propertyType">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button class="right" style="width: 88px; height: 36px" @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content" style="padding: 16px; background-color: #fff">
      <!--搜索条件区域-->
      <div class="padding20">
        <TitleModule :title="'查看物品类型'"></TitleModule>
        <!-- <info-table
          :tableData="tableData"
          :num="1"
          :wrapperStyle="{ border: 0 }"
          :valueStyle="{ width: 'calc(100% - 100px)', 'text-align': 'left' }"
          :nameStyle="{
            background: '#fff',
            width: '100px',
            'text-align': 'right',
          }"
        >
        </info-table> -->
        <info-table :tableData="tableData" :num="1"> </info-table>
      </div>
    </div>
  </div>
</template>
<script>
import infoTable from "@/components/infoTable/infoTableProperty";
import TitleModule from "@/components/titleModule/titleModule";
export default {
  components: {
    infoTable,
    TitleModule,
  },
  data() {
    return {
      tableData: {},
      viewData: {},
    };
  },
  methods: {
    setTableData() {
      this.tableData = [
        {
          value: this.viewData.operationName,
          name: "所属商户：",
          col: 1,
        },
        {
          value: this.viewData.assetsTypeName,
          name: "类型名称：",
          col: 1,
        },
        {
          value: this.viewData.remarks,
          name: "备注：",
          col: 1,
        },
      ];
    },
  },
  mounted() {
    this.viewData = this.$route.query;
    this.setTableData();
  },
};
</script>
<style scoped>
.searchWrapper {
  width: 100%;
}
.propertyType .breadcrumb {
  height: 35px;
  margin-bottom: 10px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "propertyType" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            staticStyle: { width: "88px", height: "36px" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "content",
        staticStyle: { padding: "16px", "background-color": "#fff" },
      },
      [
        _c(
          "div",
          { staticClass: "padding20" },
          [
            _c("TitleModule", { attrs: { title: "查看物品类型" } }),
            _c("info-table", { attrs: { tableData: _vm.tableData, num: 1 } }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }